export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/__layout-error.svelte"),
	() => import("../../src/routes/[...catchall].svelte"),
	() => import("../../src/routes/account.svelte"),
	() => import("../../src/routes/auction/[auction_id=integer].svelte"),
	() => import("../../src/routes/auction/[auction_id]/lot/[lot_id=integer].svelte"),
	() => import("../../src/routes/auctions/[...catchall].svelte"),
	() => import("../../src/routes/confirm-account/[...token].svelte"),
	() => import("../../src/routes/error@error.svelte"),
	() => import("../../src/routes/foo.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/permission-denied@error.svelte"),
	() => import("../../src/routes/results/auction/[auction_id=integer].svelte"),
	() => import("../../src/routes/results/auction/[auction_id]/lot/[lot_id=integer].svelte"),
	() => import("../../src/routes/signup.svelte"),
	() => import("../../src/routes/user/profile.svelte")
];

export const dictionary = {
	"": [[0, 11], [1]],
	"account": [[0, 4], [1]],
	"error@error": [[2, 9], [1]],
	"foo": [[0, 10], [1]],
	"permission-denied@error": [[2, 12], [1]],
	"signup": [[0, 15], [1]],
	"user/profile": [[0, 16], [1]],
	"results/auction/[auction_id=integer]": [[0, 13], [1]],
	"results/auction/[auction_id]/lot/[lot_id=integer]": [[0, 14], [1]],
	"auction/[auction_id=integer]": [[0, 5], [1]],
	"auction/[auction_id]/lot/[lot_id=integer]": [[0, 6], [1]],
	"auctions/[...catchall]": [[0, 7], [1]],
	"confirm-account/[...token]": [[0, 8], [1]],
	"[...catchall]": [[0, 3], [1]]
};